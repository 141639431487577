  import React, { useState, useEffect } from "react";
  import { Card, Col, Row ,Button} from 'react-bootstrap';
  import IconButton from 'components/common/IconButton';
  import classNames from 'classnames';
  import PropTypes from 'prop-types';
  import axios from 'axios';
  import { Link } from 'react-router-dom';
  import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
  import AdvanceTable from 'components/common/advance-table/AdvanceTable';
  import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
  import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
  import { ResponseFromServer } from 'helpers/utils';
  import DateFilter from './leadfillter';
  import Badge from 'components/common/SoftBadge';
  import Editlead from './editlead';
  import ViewLeadInfo from './viewleadinfo';
  import {
  BrowserRouter as Router,
  Route,
  useParams,
  useNavigate
  } from 'react-router-dom';
  const LeadHistory = (setRedirect, setRedirectUrl) => {
  const [lgShow, setLgShow] = useState(false);
  const [leadList,setLeadList] = useState([]);
  const [date, setDate] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [images,setImages] = useState([])

  const  navigate = useNavigate()
  const getUserList =()=>{
  ResponseFromServer('leadinfo/leaddetails','lead').then((res)=>setLeadList(res.data))

  }
  useEffect(() => {
    getUserList();
    return () => {
      setLeadList([]);
    };
  }, []);

  useEffect(getUserList,[lgShow])



  const getFilteredList = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    navigate(`/leadinfo/leaddetails?followUpDate=${selectedDate}`)
    ResponseFromServer('leadinfo/leaddetails','lead').then((res) => setLeadList(res.data));
  }

  const columns =  [
  {
    accessor: 'id',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
    className: 'py-2'
  },
    Cell: ({row }) => (
          <strong><Link className="text-info" onClick={() => {
          handleView(row.original.id);
        }} >{row.original.name}</Link></strong>
    )
  },
  {
    accessor: 'email',
    Header: 'Email',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    }
  },

  {
    accessor: 'phoneNumber',
    Header: 'Phone',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    }
  },
  {
    accessor: 'address',
    Header: 'Address',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    }
  },
  {
    accessor: 'followUpDate',
    Header: 'Date',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    }
  }
  ];

  const handleView = (id,image) => {

    setLgShow(!lgShow);
  setImages(image)
         ResponseFromServer('leadinfo/leaddetails',`lead/${id}`).then((res)=>setSelectedItemData(res.data))


  };

  return (

  <Card>
  <Card.Header className="bg-light">
  <Row className="align-items-center">
  <Col>
    <h5 className="mb-0">Leads Details</h5>
  </Col>
  </Row>
  </Card.Header>
  <Card.Body>
  <AdvanceTableWrapper
  columns={columns}
  data={leadList}
  sortable
  pagination
  perPage={5}
  >
   <Row className="flex-end-center mb-3">
     <Col >
       <AdvanceTableSearchBox table/>
     </Col>
     <Col xs="auto" sm={6} lg={4}>
         <DateFilter setData={getFilteredList}  date={date}/>
     </Col>
   </Row>

   <AdvanceTable
     table
     headerClassName="bg-200 text-900 text-nowrap align-middle"
     rowClassName="align-middle white-space-nowrap"
     tableProps={{
       bordered: true,
       striped: true,
       className: 'fs--1 mb-0 overflow-hidden'
     }}
   />

   <div className="mt-3">
          <AdvanceTablePagination
            table
  />
        </div>

  </AdvanceTableWrapper>

    <ViewLeadInfo lgShow={lgShow} setLgShow={setLgShow}  data={selectedItemData}  images={images} />

  </Card.Body>


  </Card>

  );
  }


  export default LeadHistory;
