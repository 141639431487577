import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendData, sendDataLogin } from "helpers/utils";
import { useAuthDispatch,loginSuccess,loginError,useAuthState} from 'context/auth'
import withRedirect from "components/hoc/withRedirect";

const LoginForm = ({ hasLabel, layout, setRedirect, setRedirectUrl }) => {
  // State
  const dispatch = useAuthDispatch()
  const {errorMessage} = useAuthState();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  const [trigger,setTrigger] = useState(false)
  const [appData,setAppData] = useState([])


  // Handler


  const handleSubmit = e => {
    e.preventDefault();
    const tenantResolver = {"username":formData.email}
     sendData("signin","tenant/resolve",tenantResolver)
     .then((res)=>{
       if(res.data.length >= 2){
          setTrigger(true)
          setAppData(res.data)
       }
       else{
         handleLogin(res.data[0])

       }
     }).catch((error)=>{loginError(dispatch,error.response.data)

     })


  };

    const handleLogin = (data)=>{
      const userLogin =  {username:formData.email,password:formData.password}
      let tenant = data.tenantId
      sendDataLogin("signin","login",userLogin,tenant)
      .then((res)=>{
          loginSuccess(dispatch,res.data.access,res.data.refresh)
          setRedirect(true);
          setRedirectUrl('/')
          toast.success(`Logged in as ${formData.email}`, {
            theme: 'colored'
          });
      })


    }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
    {trigger?(
      <div className="row">
      {appData.map((data,idx)=>(

        <div className="col-6 mt-2" key={idx}>
          <button type="button" className="btn btn-outline-warning" onClick={()=>handleLogin(data)} >{data.tenantName}</button>
        </div>
      ))}
      </div>):(
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
      {errorMessage?<div className="mt-3 w-100 bg-danger text-white rounded text-center">{errorMessage}</div>:null}
      <Divider className="mt-4">or log in with</Divider>
    </Form>
    )}
  </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default withRedirect(LoginForm);
