import React, { useState,useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {sendData} from 'helpers/utils';

const ProductStatus = ({orderId,statusDataApi}) => {

  const statusMetadata = [
    {
        "name": "pending",
        "value": "Pending",
        "attributes": [
            {
                "name": "stateColor",
                "value": "gray"
            }
        ]
    },
    {
        "name": "completed",
        "value": "Completed",
        "attributes": [
            {
                "name": "stateColor",
                "value": "green"
            }
        ]
    },
    {
        "name": "in_progress",
        "value": "In Progress",
        "attributes": [
            {
                "name": "stateColor",
                "value": "blue"
            }
        ]
    },
    {
        "name": "customer_review",
        "value": "Customer Review",
        "attributes": [
            {
                "name": "stateColor",
                "value": "red"
            }
        ]
    },
    {
        "name": "fabric_unavailable",
        "value": "Fabric Unavailable",
        "attributes": [
            {
                "name": "stateColor",
                "value": "red"
            }
        ]
    },
    {
        "name": "production_issue",
        "value": "Production Issue",
        "attributes": [
            {
                "name": "stateColor",
                "value": "red"
            }
        ]
    },
    {
        "name": "packaging_issue",
        "value": "Packaging Issue",
        "attributes": [
            {
                "name": "stateColor",
                "value": "red"
            }
        ]
    },
  {
        "name": "shipping_ready",
        "value": "Ready for Shipping",
        "attributes": [
            {
                "name": "stateColor",
                "value": "blue"
            }
        ]
    },
  {
        "name": "shipped",
        "value": "Shipped",
        "attributes": [
            {
                "name": "stateColor",
                "value": "blue"
            }
        ]
    },
  {
        "name": "delivered",
        "value": "Delivered",
        "attributes": [
            {
                "name": "stateColor",
                "value": "green"
            }
        ]
    }
]



  const [status, setStatus] = useState([
  {
    "statusType": "designStatus",
    "initialState": "pending",
    "finalState": "completed",
    "states": [
      {
        "currentState": "pending",
        "nextStates": [
          "in_progress"
        ],
        "blockSates": [
          "customer_review"
        ]
      },
      {
        "currentState": "in_progress",
        "nextStates": [
          "completed"
        ],
        "blockSates": [
          "customer_review"
        ]
      },
      {
        "currentState": "customer_review",
        "nextStates": [
          "in_progress",
          "completed"
        ],
        "blockSates": []
      },
      {
        "currentState": "completed",
        "nextStates": [
          "pending"
        ],
        "blockSates": []
      }
    ]
  },
  {
    "statusType": "productStatus",
    "initialState": "pending",
    "finalState": "completed",
    "states": [
      {
        "currentState": "pending",
        "nextStates": [
          "in_progress"
        ],
        "blockSates": [
          "fabric_unavailable"
        ]
      },
      {
        "currentState": "in_progress",
        "nextStates": [
          "completed"
        ],
        "blockSates": [
          "production_issue"
        ]
      },
      {
        "currentState": "fabric_unavailable",
        "nextStates": [
          "in_progress",
          "completed"
        ],
        "blockSates": []
      },
      {
        "currentState": "production_issue",
        "nextStates": [
          "in_progress",
          "completed"
        ],
        "blockSates": []
      },
      {
        "currentState": "completed",
        "nextStates": [
          "pending"
        ],
        "blockSates": []
      }
    ]
  },
  {
    "statusType": "printStatus",
    "initialState": "pending",
    "finalState": "completed",
    "states": [
      {
        "currentState": "pending",
        "nextStates": [
          "in_progress"
        ],
        "blockSates": [
          "customer_review"
        ]
      },
      {
        "currentState": "in_progress",
        "nextStates": [
          "completed"
        ],
        "blockSates": [
          "customer_review"
        ]
      },
      {
        "currentState": "customer_review",
        "nextStates": [
          "in_progress",
          "completed"
        ],
        "blockSates": []
      },
      {
        "currentState": "completed",
        "nextStates": [
          "pending"
        ],
        "blockSates": []
      }
    ]
  },
  {
    "statusType": "parcelStatus",
    "initialState": "pending",
    "finalState": "completed",
    "states": [
      {
        "currentState": "pending",
        "nextStates": [
          "in_progress"
        ],
        "blockSates": [
          "packaging_issue"
        ]
      },
      {
        "currentState": "in_progress",
        "nextStates": [
          "completed"
        ],
        "blockSates": [
          "packaging_issue"
        ]
      },
      {
        "currentState": "packaging_issue",
        "nextStates": [
          "in_progress",
          "completed"
        ],
        "blockSates": []
      },
      {
        "currentState": "completed",
        "nextStates": [
          "pending"
        ],
        "blockSates": []
      }
    ]
  },
  {
    "statusType": "orderStatus",
    "initialState": "pending",
    "finalState": "delivered",
    "states": [
      {
        "currentState": "pending",
        "nextStates": [
          "in_progress"
        ],
        "blockSates": []
      },
      {
        "currentState": "in_progress",
        "nextStates": [
          "shipping_ready"
        ],
        "blockSates": []
      },
      {
        "currentState": "shipping_ready",
        "nextStates": [
          "shipped"
        ],
        "blockSates": []
      },
      {
        "currentState": "shipped",
        "nextStates": [
          "delivered"
        ],
        "blockSates": []
      }
    ]
  }
]);

const updateData = () => {
  if (statusDataApi && statusDataApi.length !== 0) {
    setStatus(prevStatus => {
      return prevStatus.map(statusItem => {
        const apiStatus = statusDataApi.find(apiItem => apiItem.statusType === statusItem.statusType);
        if (apiStatus) {
          return {
            ...statusItem,
            initialState: apiStatus.status
          };
        }
        return statusItem;
      });
    });
  } else {
    console.log("Set the initial status");
  }
};

useEffect(updateData,[])

  const [currentStatusType, setCurrentStatusType] = useState(status[0].statusType);
  const [currentState, setCurrentState] = useState(status[0].states[0]);
  const [statusModal, setStatusModal] = useState(false);
  const [noteToggle,setNoteToggle] = useState(false);
  const [note,setNote] = useState(null)

  const openModal = (statusType) => {
    const selectedStatus = status.find(s => s.statusType === statusType);
    setCurrentStatusType(statusType);
    setCurrentState(selectedStatus.states.find(state => state.currentState === selectedStatus.initialState.toLowerCase()));
    setStatusModal(true);
  };


  let [tempData,setTempData] = useState(null);
  const changeStatus = async (e, newState) => {
  e.preventDefault();

  if (newState === undefined && tempData) {
    newState = tempData;
  } else {
    setTempData(newState);
    setNoteToggle(true);
    return;
  }

  let statusData = {
    statusType: currentStatusType,
    status: newState,
    note: note
  };

  try {
    await sendData('orders/order-list', `status/${orderId}`, statusData);
    setStatus(prevStatus => {
      const updatedStatus = prevStatus.map(statusItem => {
        if (statusItem.statusType === currentStatusType) {
          const updatedStates = statusItem.states.map(state =>
            state.currentState === newState ? { ...state } : state
          );
          return {
            ...statusItem,
            initialState: newState,
            states: updatedStates
          };
        }
        return statusItem;
      });

      const selectedStatus = updatedStatus.find(s => s.statusType === currentStatusType);
      setCurrentState(selectedStatus.states.find(state => state.currentState === newState));
      return updatedStatus;
    });
    setHideModal()
  } catch (error) {
    console.error("Error updating status:", error);
  }
};



  const getStatusMetadata = (stateName) => {
    return statusMetadata.find(meta => meta.name === stateName.toLowerCase());
  };

  const customMetod = (state)=>{
      let meta = getStatusMetadata(state)
      return meta.attributes[0].value
  }
  const customName=(state)=>{
     let meta = getStatusMetadata(state)
     return meta.value

  }
  const setHideModal = () => {
  setNoteToggle(false);
  setStatusModal(false)
  };


  return (
    <>
      <Modal show={statusModal} onHide={ setHideModal }>
        {noteToggle === true ?(<>
              <Modal.Header closeButton>
                <h4>Write A Note</h4>
              </Modal.Header>
              <Modal.Body>
              <div className="form-floating">
                <textarea className="form-control" placeholder="Leave a comment here"  onChange={(e)=>setNote(e.target.value)}></textarea>
                <label htmlFor="floatingTextarea">Comments</label>
              </div>

              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary text-uppercase" onClick={changeStatus} >
                  Save
                </button>
              </Modal.Footer>

          </>):(
          <>
          <Modal.Header closeButton>
            <h4>{currentStatusType.replace('Status', ' Status')}</h4>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h5>Move To</h5>
              <hr />
              <div className="d-flex justify-content-around">
                {
                  currentState?.nextStates?.map((item, idx) => {
                    const meta = getStatusMetadata(item)
                    return(
                    <button className="btn btn-primary " style={{backgroundColor:`${meta.attributes[0].value}` }}   onClick={(e)=>changeStatus(e,item)} key={idx}>
                      {meta.value}
                    </button>)
                  })
                }
              </div>
              <hr />
              <h5>Blocked</h5>
              {
                currentState?.blockSates?.map((item, idx) => {
                  const meta = getStatusMetadata(item)
                  return(
                    <button className="btn btn-primary " style={{backgroundColor:`${meta.attributes[0].value}` }}   onClick={(e)=>changeStatus(e,item)} key={idx}>
                      {meta.value}
                    </button>)
                })
              }
            </div>
          </Modal.Body>
          </>
        )}

      </Modal>

      <div className="rounded rounded-3 mx-2 d-none d-lg-flex my-2">
        <div className=" border d-flex flex-column rounded rounded-4 rounded-end p-2">
          <h6 className="text-dark mx-auto my-1">Design Status</h6>
          <button   style={{backgroundColor:customMetod(status[0].initialState)}}  className="btn btn-primary mx-auto my-2" onClick={() => openModal("designStatus")}>
            {customName(status[0].initialState)}
          </button>
        </div>
        <div className="border  d-flex flex-column p-2">
          <h6 className="text-dark mx-auto my-1">Product Status</h6>
          <button  style={{backgroundColor:customMetod(status[1].initialState)}} className="btn btn-primary mx-auto my-2"  onClick={() => openModal("productStatus")}>
            {customName(status[1].initialState)}
          </button>
        </div>
        <div className="border  d-flex flex-column p-2">
          <h6 className="text-dark mx-auto my-1">Print Status</h6>
          <button className="btn btn-primary mx-auto my-2" style={{backgroundColor:customMetod(status[2].initialState)}} onClick={() => openModal("printStatus")}>
            {customName(status[2].initialState)}
          </button>
        </div>
        <div className="border  d-flex flex-column p-2">
          <h6 className="text-dark mx-auto my-1">Parcel Status</h6>
          <button style={{backgroundColor:customMetod(status[3].initialState)}} className="btn btn-primary mx-auto my-2" onClick={() => openModal("parcelStatus")}>
            {customName(status[3].initialState)}
          </button>
        </div>
        <div className="border  d-flex flex-column rounded rounded-4 rounded-start p-2">
          <h6 className="text-dark mx-auto my-1">Order Status</h6>
          <button style={{backgroundColor:customMetod(status[4].initialState)}} className="btn btn-primary mx-auto my-2" onClick={() => openModal("orderStatus")}>
            {customName(status[4].initialState)}
          </button>
        </div>
      </div>

      <div className="rounded d-lg-none d-sm-block">
        <div className="d-flex mt-3">
          <span className="fw-bolder my-auto">Design Status</span>
          <DropdownButton id="dropdown-basic-button" className="ms-auto" title="Pending">
            <Dropdown.Item onClick={() => openModal("designStatus")}>View</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="d-flex mt-3">
          <span className="fw-bolder my-auto">Product Status</span>
          <DropdownButton id="dropdown-basic-button" className="ms-auto my-2" title="Pending">
            <Dropdown.Item onClick={() => openModal("productStatus")}>View</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="d-flex mt-3">
          <span className="fw-bolder my-auto">Print Status</span>
          <DropdownButton id="dropdown-basic-button" className="ms-auto my-2" title="Pending">
            <Dropdown.Item onClick={() => openModal("printStatus")}>View</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="d-flex mt-3">
          <span className="fw-bolder my-auto">Parcel Status</span>
          <DropdownButton id="dropdown-basic-button" className="ms-auto my-2" title="Pending">
            <Dropdown.Item onClick={() => openModal("parcelStatus")}>View</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    </>
  );
};

export default ProductStatus;
