import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  weeklySalesData,
  totalOrder,
  marketShare
} from 'data/dashboard/default';
import WeeklySales from './WeeklySales';
import TotalOrder from './TotalOrder';
import MarketShare from './MarketShare';

const Dashboard = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <WeeklySales data={weeklySalesData} />
        </Col>
        <Col md={6} xxl={3}>
          <TotalOrder data={totalOrder} />
        </Col>
        <Col md={6} xxl={3}>
          <MarketShare data={marketShare} radius={['100%', '87%']} />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
