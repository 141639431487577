import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { Button} from 'react-bootstrap';
import {useState,useEffect} from "react";
import {Modal} from 'react-bootstrap';
import Createlead from './createlead';

const LeadHeader = (props) => {
  const [smShow, setSmShow] = useState(false);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <Row className="align-items-center">
          <Col>
            <h5 className="mb-0">Leads</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
      <div>
        <strong><Button variant="primary"  icon="plus" onClick={()=>setSmShow(!smShow)}>Create Lead</Button></strong>
      </div>
      </Card.Body>

        <Createlead smShow={smShow} setSmShow={setSmShow}/>
    </Card>
  );
};

export default LeadHeader;
