import React,{useEffect, useState} from "react";
import { Form, Button, Col, Row } from 'react-bootstrap';
import { sendData, sendDataLogin } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthDispatch,loginSuccess,useAuthState,loginError} from 'context/auth'
import withRedirect from "components/hoc/withRedirect";
const SocialAuthButtons = ({ hasLabel, layout, setRedirect, setRedirectUrl }) => {
  const dispatch = useAuthDispatch()
  const { loading, errorMessage } = useAuthState();
  const [gsiscriptloaded, setgsiscriptloaded] = useState(false)
  const [trigger,setTrigger] = useState(false)
  const [appData,setAppData] = useState([])
  const [credential,setCredential] = useState({})


  const  parseJwt=(token)=> {
      var base64Payload = token.split('.')[1];
      var payload = Buffer.from(base64Payload, 'base64');
      return JSON.parse(payload.toString());
  }



  const handlegooglesignin = (res) => {
    let data = parseJwt(res.credential)
    setCredential({clientId: res.clientId,credential: res.credential,select_by:res.select_by})
    const tenantResolver = {"username":data.email}
     sendData("signin","tenant/resolve",tenantResolver)
     .then((res)=>{
        if(res.data.length >= 2){
            setTrigger(true)
            setAppData(res.data)
        }
        else{
          handleLogin(res.data[0])

        }

     }).catch((error)=>{loginError(dispatch,error.response.data)
     })
  }

  /*google Login initialize */

    useEffect(() => {
        const initializeGsi = () => {
            setgsiscriptloaded(true)
            window.google.accounts.id.initialize({
                client_id:process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handlegooglesignin,
            })
        }

        const script = document.createElement("script")
        script.src = "https://accounts.google.com/gsi/client"
        script.onload = initializeGsi
        script.async = true
        script.id = "google-client-script"
        document.querySelector("body").appendChild(script)
        return () => {
            document.getElementById("google-client-script").remove()
        }
    },[handlegooglesignin])

    /* Handle Google Auto Login*/

      useEffect(() => {
        window.onload = function () {
            var refresh = localStorage.getItem("refresh")
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: (data) => handlegooglesignin(data),
            })
            if (refresh === null) {
                window.google.accounts.id.prompt()
            }
        }
      },[handlegooglesignin])


    /* Handle Google Auto Login End*/

    const handleLogin = (data)=>{
      let tenant = data.tenantId
      sendDataLogin("signin","login/google",credential,tenant)
      .then((res)=>{
          loginSuccess(dispatch,res.data.access,res.data.refresh)
          setRedirect(true);
          setRedirectUrl('/')
          toast.success(`Logged in as ${formData.email}`, {
            theme: 'colored'
          });
      })
      .catch((err)=>{
          console.log(err)
      })

    }





  return(<>
    {trigger?(<>
    <div className="row">
        {appData.map((data,idx)=>(
          <div className="col-6 mt-2" key={idx}>
            <button type="button" className="btn btn-outline-warning" onClick={()=>handleLogin(data)} >{data.tenantName}</button>
          </div>
          ))}
      </div>

    </>):(
        <Form.Group className="mb-0">
          <Row>
            <Col sm={12} className="pe-sm-1">

            <div className="g_id_signin"
               data-type="standard"
               data-theme="outline"
               data-text="signin_with"
               data-size="large"
               data-logo_alignment="left"
               data-width="290"
               />

            </Col>
          </Row>
        </Form.Group>)}
      </>)

};



export default withRedirect(SocialAuthButtons);
