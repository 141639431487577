import React, { useState, useEffect,useCallback } from "react";
import { Modal, Row, Col, Card, Form,Image,Button } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import IconButton from 'components/common/IconButton';
import classNames from 'classnames';
import Axios from "axios";
import { PutToServer,PutToImage } from 'helpers/utils';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import CardDropdown from 'components/common/CardDropdown';
import { getSize } from 'helpers/utils';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ViewLeadInfo = (props) => {
  const [loader,setLoader] = useState(true);

  const initialData = props.data || {
    name: '',
    email: '',
    phone: '',
    id: '',
    address:'',
    companyName:'',
    gender:'',
    society: '',
    pinCode:'',
    state: '',
    city: '',
    country: '',
    followUpDate:'',
    categoryType: '',
   neckType:'',
   fittingType: '',
   fabric:'',
   vendor: '',
   color: '',
   printingType: '',
   placements: '',
   quantity: '',
   rate: '',
   placements:'',
   images:[]
  };

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialData);
 const [images, setImages] = useState([]);
 const [uploadedImages, setUploadedImages] = useState([]);
  const [uploading, setUploading] = useState(false);
   const toggleEdit = () => {
    if (isEditing) {
      let data = {
        name: text.name,
        email: text.email,
        phoneNumber: text.phoneNumber,
        address:text.address,
        companyName:text.companyName,
        gender:text.gender,
        society: text.society,
        pinCode:text.pinCode,
        state: text.state,
        city: text.city,
        country: text.country,
        followUpDate:text.followUpDate,
        categoryType: text.categoryType,
       neckType:text.neckType,
       fittingType: text.fittingType,
       fabric:text.fabric,
       vendor: text.vendor,
       color: text.color,
       printingType: text.printingType,
       placements: text.placements,
       quantity: text.quantity,
       rate: text.rate,
       placements:text.placements,
       images:text.images
      }
     PutToServer('/leadinfo/leaddetails', `/lead/${props.data.id}`, data).then((res)=>{
      console.log(res)
     })
    }
    setIsEditing(!isEditing);
  };


  useEffect(() => {
    if (props.data) {
      setText({
        name: props.data.name || '',
        email: props.data.email || '',
        phone: props.data.phoneNumber || '',
        id: props.data.id || '',
        phoneNumber: props.data.phoneNumber || '',
        address:props.data.address || '',
        companyName:props.data.companyName ||'',
        gender:props.data.gender||'',
        society: props.data.society || '',
        pinCode:props.data.pinCode || '',
        state: props.data.state || '',
        city: props.data.city || '',
        country: props.data.country || '',
        followUpDate:props.data.followUpDate ||'',
        categoryType: props.data.categoryType ||'',
       neckType:props.data.neckType||'',
       fittingType: props.data.fittingType||'',
       fabric:props.data.fabric||'',
       vendor: props.data.vendor||'',
       color: props.data.color||'',
       printingType: props.data.printingType||'',
       placements: props.data.placements||'',
       quantity: props.data.quantity||'',
       rate: props.data.rate||'',
       placements:props.data.placements||'',
      images:props.data.images||[]
      });
    }

  }, [props.data]);
  const handleChange = (e, field) => {
    setText({ ...text, [field]: e.target.value });
    };



const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setImages([...images, ...acceptedFiles]);
    }
  });

  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };


  const handleImageUpload = async () => {
    setUploading(true);
    setLoader(false);
    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append(`files`, image);
    });


    try {

      const response = await PutToImage(`/lead/${props.data.id}/images`, formData);

      if (response.status === 200) {
        setLoader(true)
       const data = await dataResponse.json();

        setText({ ...text, images: dataResponse.urls });
        setImages([]);


      } else {

        console.error('Image upload failed');
      }
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      setUploading(false);
      setImages([]);
       toast.success('Images uploaded successfully');
    }
  };
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleRemoveImage = (idx) => {
    setSelectedImageIndex(idx);
    setShowConfirmation(true);
  };

  const handleConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <>

      {props.data === null ? (null) : (
        <Modal
          size="xl"
          show={props.lgShow}
          onHide={() => props.setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Lead Details
            </Modal.Title>
            <FalconCloseButton onClick={() => props.setLgShow(false)} />
          </Modal.Header>
          <Modal.Body>
            <Card className="mb-3">
              <Card.Header>
                <Row className="align-items-center">
                  <Col>
                    <h5 className="mb-0">{text.name}</h5>
                  </Col>
                  <Col xs="auto">
                    <IconButton
                      iconClassName="fs--2 me-1"
                      variant="falcon-default"
                      size="sm"
                      icon="close"
                      onClick={toggleEdit}
                    >
                      {isEditing ? 'Save' : 'Edit'}
                    </IconButton>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="bg-light border-top">

                <Row>
                  <Col >
                    <h6 className="fw-semi-bold ls mb-3 text-uppercase">Personal Information</h6>
                    {isEditing ? (
                      <>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Name</p>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              value={text.name}
                              onChange={(e) => handleChange(e, 'name')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Email</p>
                          </Col>
                          <Col sm={8}>
                            <Form.Control
                              type="text"
                              value={text.email}
                              onChange={(e) => handleChange(e, 'email')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Phone</p>
                          </Col>
                          <Col >
                            <Form.Control
                              type="text"
                              value={text.phoneNumber}
                              onChange={(e) => handleChange(e, 'phoneNumber')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Address</p>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              value={text.address}
                              onChange={(e) => handleChange(e, 'address')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Company Name</p>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              value={text.companyName}
                              onChange={(e) => handleChange(e, 'companyName')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Gender</p>
                          </Col>
                          <Col>
                            <Form.Select className="mb-2"   value={text.gender}
                              onChange={(e) => handleChange(e, 'gender')}>
                        <option value=''>Gender</option>
                        <option value='MALE'>MALE</option>
                        <option value='FEMALE'>FEMALE</option>
                        <option value='UNISEX'>UNISEX</option>
                      </Form.Select>
                          </Col>
                        </Row>


                      </>
                    ) : (
                      <>
                        <Row >
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3 ">Name</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{text.name}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3 ">Email</p>
                          </Col>
                          <Col sm={8}>
                            <p className="fw-semi-bold mb-1">{text.email}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3 ">Phone</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{text.phoneNumber}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3 ">Address</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{text.address}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3 ">Company Name</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{text.companyName}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3 ">Gender</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{text.gender}</p>
                          </Col>
                        </Row>


                        {/* Add similar fields for email, phone, etc. */}
                      </>
                    )}
                  </Col>
                  <Col>
            <Row >
            <Col >
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">Order Information</h6>
              {isEditing ? (
                <>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3">Category Type</p>
                    </Col>
                    <Col>
                        <Form.Select className="mb-2" value={text.categoryType}
                      onChange={(e) => handleChange(e, 'categoryType')}>
                  <option value=''>Category Type</option>
                  <option value='TSHIRT'>TSHIRT </option>
                  <option value='HOODIE'>HOODIE</option>
                  <option value='JACKET'> JACKET</option>
                  <option value='SWEATSHIRT'>SWEATSHIRT</option>
                  <option value='BAG'>BAG</option>
                  <option value='MASK'>MASK</option>
                  <option value='VEST'> VEST</option>
                  <option value='TANK_TOP'>TANK_TOP</option>


                </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3">Neck Type</p>
                    </Col>
                    <Col sm={8}>
                      <Form.Select className="mb-2" value={text.neckType}
                      onChange={(e) => handleChange(e, 'neckType')}
                    >
                <option value=''>Neck Type</option>
                <option value='ROUND_NECK'>ROUND_NECK</option>
                <option value=' V_NECK'> V_NECK</option>
                <option value='DEEP_NECK'> DEEP_NECK</option>
              </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3">Fitting Type</p>
                    </Col>
                    <Col >
                    <Form.Select className="mb-2" value={text.fittingType}
                  onChange={(e) => handleChange(e, 'fittingType')}>
              <option value=''>Fitting Type</option>
              <option value='SLIM'>SLIM</option>
              <option value='REGULAR'>REGULAR</option>
              <option value='LOOSE'> LOOSE</option>
              <option value='OVER_SIZE'>OVER_SIZE</option>
              <option value='FREE_SIZE'>FREE_SIZE</option>
              <option value='DROP_SHOULDER'>DROP_SHOULDER</option>
              <option value='STANDARD'> STANDARD</option>
            </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3">Fabric</p>
                    </Col>
                    <Col>
                      <Form.Select className="mb-2" v  value={text.fabric}
                        onChange={(e) => handleChange(e, 'fabric')}>
                  <option value=''>Fabric</option>
                  <option value=' PURE_COTTON'> PURE_COTTON</option>
                  <option value='POLYESTER'> POLYESTER</option>
                  <option value='POLY_COTTON'> POLY_COTTON</option>
                </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3">Placement</p>
                    </Col>
                    <Col>
                      <Form.Select className="mb-2" v  value={text.placements}
                        onChange={(e) => handleChange(e, 'placements')}>
                  <option value=''>Placements</option>
                  <option value=' FRONT'>FRONT</option>
                  <option value=' BACK'> BACK</option>
                  <option value='LEFT'>LEFT</option>
                  <option value='RIGHT'>RIGHT</option>
                </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3">Vendor</p>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        value={text.vendor}
                        onChange={(e) => handleChange(e, 'vendor')}
                      />
                    </Col>
                  </Row>

                </>
              ) : (
                <>
                  <Row >
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3 ">Category Type</p>
                    </Col>
                    <Col>
                      <p className="fw-semi-bold mb-1">{text.categoryType}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3 ">Neck Type</p>
                    </Col>
                    <Col sm={8}>
                      <p className="fw-semi-bold mb-1">{text.neckType}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3 ">Fitting Type</p>
                    </Col>
                    <Col>
                      <p className="fw-semi-bold mb-1">{text.fittingType}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3 ">Fabric</p>
                    </Col>
                    <Col>
                      <p className="fw-semi-bold mb-1">{text.fabric}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3 ">Placement</p>
                    </Col>
                    <Col>
                      <p className="fw-semi-bold mb-1">{text.placements}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={4}>
                      <p className="fw-semi-bold ls mb-3 ">Vendor</p>
                    </Col>
                    <Col>
                      <p className="fw-semi-bold mb-1">{text.vendor}</p>
                    </Col>
                  </Row>
                  <Row>

                  </Row>

                  {/* Add similar fields for email, phone, etc. */}
                </>
              )}
            </Col>
            </Row>
              </Col>
        </Row>

<Row>
<Col>
<Row >
  <h6 className="fw-semi-bold ls mb-3 text-uppercase">Images</h6>
  {loader?(
    text.images.map((item, idx) => (

              <Col key={idx} className="g-3 " >


<Card className="bg-dark text-white light" style={{ width: '15rem' }}>
<Card.ImgOverlay className="d-flex flex-row justify-content-end">
<FalconCloseButton
         className="btn-close"
         onClick={() => handleRemoveImage(idx)}
       />
  </Card.ImgOverlay>
              <Card.Img
                src={item.imageDesktop}
                  rounded
                   className="me-3 d-none d-md-block"
                    height="150"
              alt={`product image ${idx + 1}`}
  />
</Card>
  </Col>
  ))
  ):(<span>Loading....</span>)}

</Row>
<Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Removal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove this image?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelConfirmation}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

</Col>
<Col>
<Row>
<div {...getRootProps({ className: 'dropzone-area py-6' })} >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some images here, or click to select images</p>
      </div>
      <div>
        {images.map((file, index) => (
          <Flex
alignItems="center"
className="py-3 border-bottom btn-reveal-trigger"
key={file.name}
>
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              width={100}
              height={100}
            />
            <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
            <div>
<h6>{file.name}</h6>
<Flex className="position-relative" alignItems="center">
            <p className="mb-0 fs--1 text-400 line-height-1">
  <strong>
    {getSize(file.size)}
  </strong>
</p>
</Flex>
</div>
</Flex>

<CardDropdown>
<div className="py-2">
<Dropdown.Item className="text-danger" onClick={() => removeImage(index)}>
Remove
</Dropdown.Item>
</div>
</CardDropdown>
</Flex>

        ))}
      </div>
      <button onClick={handleImageUpload} disabled={images.length === 0 || uploading}>
        {uploading ? 'Uploading...' : 'Upload Images'}
      </button>
      {uploadedImages.length > 0 && (
        <div>
          <h2>Uploaded Images:</h2>
          <ul>
            {uploadedImages.map((url, index) => (
              <li key={index}>
                <img src={url} alt={`Uploaded Image ${index}`} width={100} />
              </li>
            ))}
          </ul>
        </div>
      )}
      </Row>
</Col>

</Row>



                <Row>
        <Col>
        </Col>

      </Row>

              </Card.Body>
              <Card.Footer className="border-top text-end">

              </Card.Footer>
            </Card>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default ViewLeadInfo;
