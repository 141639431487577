import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import {useAuthState, logoutSuccess, useAuthDispatch} from "context/auth";
import withRedirect from "components/hoc/withRedirect";
import { toast } from 'react-toastify';

const ProfileDropdown = ({setRedirect, setRedirectUrl}) => {

  const userProfile = useAuthState()
  const dispatch = useAuthDispatch()

  const checkLogout = (e)=>{
		e.preventDefault()
		logoutSuccess(dispatch)
    setRedirect(true)
    setRedirectUrl('/signin')
    toast.success(`Logged out Successfully`, {
      theme: 'colored'
    });


	}
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={userProfile.profileIcon} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Divider />
          <Dropdown.Item href="#!">Set status</Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/profile">
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item href="#!">Feedback</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item>
          <Dropdown.Item as={Link} onClick={checkLogout} to="/signin">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default withRedirect(ProfileDropdown);
