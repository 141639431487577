import React, { useState,useEffect } from 'react';
import { Card, Col, Row, Form, Modal, Button, Badge } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ResponseFromServer } from 'helpers/utils';

const OrderHeader = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [currentField, setCurrentField] = useState('');
  const [orderData,setOrderData] = useState([]);

  const products = [
    'Premium Cotton Round Neck T-Shirt',
    'Raglan Full Sleeves T-Shirts',
    'Regular Cotton Hoodies',
    'Designer Cotton T-Shirt',
    'Regular Cotton Sweatshirt',
    'Regular Cotton Varsity',
    'Premium Cotton Sweatshirt',
    'Premium Oversized T-Shirt',
    'Premium 100% Cotton Full Sleeves T-Shirt',
    'Premium Cotton Hoodie',
    'Zipper Hoodies',
    'Basic Tote Bags',
    'Premium Tipped Collar Polo T-Shirt',
    'Henley Premium Cotton T-Shirt',
    'Basic Polo T-Shirt',
    'Premium 100% Cotton V-Neck T-Shirt',
    'Raglan Half Sleeves T-Shirts',
    'Zipper Tote Bags',
    'Premium Cotton Varsity',
    'Designer Cotton Full T-Shirt',
    '100% Polyester Dry Fit T-Shirt',
    'Premium Cotton Polo T-Shirt',
  ];
  const colors = [
  "white-olive",
  "yellow-black",
  "red-maroon",
  "ocean",
  "grey-mustard",
  "maroon-brown",
  "sky-black",
  "mint",
  "olivegreen",
  "olive-white",
  "black",
  "teal-blue",
  "olive",
  "sky-pink",
  "navy-maroon",
  "sky-blue",
  "mustard-white",
  "white-ocean",
  "white-black",
  "white-brown",
  "brown",
  "lilac-white",
  "white-lilac",
  "skin",
  "mustard-green",
  "orange-white",
  "white-green",
  "orange-black",
  "navy-yellow",
  "white-mustard",
  "black-mustard",
  "mustard-black",
  "maroon-navy",
  "white-grey",
  "red",
  "navy-white",
  "navy",
  "black-yellow",
  "navy-mustard",
  "grey",
  "yellow-maroon",
  "navyblue",
  "pink-white",
  "black-white",
  "purple",
  "white-orange",
  "olive-black",
  "lilac",
  "navy-orange",
  "black-maroon",
  "red-white",
  "brown-white",
  "royal blue",
  "maroon",
  "maroon-grey",
  "grey-navy",
  "mint-green",
  "royalblue",
  "yellow",
  "moss-green",
  "white",
  "maroon-mustard",
  "grey-maroon",
  "skyblue",
  "grey-black",
  "mustard",
  "red-black",
  "red-navy",
  "olive green",
  "green",
  "yellow-green",
  "white-yellow",
  "bottle-green",
  "pink",
  "maroon-white",
  "sky blue",
  "mustard yellow",
  "white-navy",
  "yellow-white",
  "sky-white",
  "olive-mustrad",
  "off-white",
  "maroon-black",
  "white-maroon",
  "orange",
  "navy blue",
  "black-brown",
  "black-orange"
];


  // Temporary input values while typing in the modal
  const [tempInputValues, setTempInputValues] = useState({
   orderID: '',
   product: [],
   color: [],
   fromOrderDate: '',
   toOrderDate: '',
   fromDeliveryDate: '',
   toDeliveryDate: '',
   deliveryType: '',
 });

 // Saved input values to be displayed
 const [inputValues, setInputValues] = useState({
   orderID: [],
   product: [],
   color: [],
   fromOrderDate: '',
   toOrderDate: '',
   fromDeliveryDate: '',
   toDeliveryDate: '',
   deliveryType: '',
 });


 const handleShow = (field) => {
   setCurrentField(field);
   setShowModal(true);
 };

 const handleClose = () => {
   setShowModal(false);
   setCurrentField('');
 };

 const handleInputChange = (e) => {
  const { name, value } = e.target;

  setTempInputValues((prevValues) => {
    let updatedValues = { ...prevValues, [name]: value };

    // Order Date Logic
    if (name === "fromOrderDate" && !prevValues.toOrderDate) {
      updatedValues.toOrderDate = value;
    } else if (name === "toOrderDate" && !prevValues.fromOrderDate) {
      updatedValues.fromOrderDate = value;
    }

    // Delivery Date Logic
    if (name === "fromDeliveryDate" && !prevValues.toDeliveryDate) {
      updatedValues.toDeliveryDate = value;
    } else if (name === "toDeliveryDate" && !prevValues.fromDeliveryDate) {
      updatedValues.fromDeliveryDate = value;
    }

    return updatedValues;
  });
};


 const handleCheckboxChange = (e) => {
   const { value, checked } = e.target;
   setTempInputValues((prevValues) => {
     const selectedProducts = [...prevValues.product];
     if (checked) {
       selectedProducts.push(value);
     } else {
       const index = selectedProducts.indexOf(value);
       if (index !== -1) {
         selectedProducts.splice(index, 1);
       }
     }
     return { ...prevValues, product: selectedProducts };
   });
 };

 const handleColorCheckboxChange = (e) => {
   const { value, checked } = e.target;
   setTempInputValues((prevValues) => {
     const selectedColors = [...prevValues.color];
     if (checked) {
       selectedColors.push(value);
     } else {
       const index = selectedColors.indexOf(value);
       if (index !== -1) {
         selectedColors.splice(index, 1);
       }
     }
     return { ...prevValues, color: selectedColors };
   });
 };

 const saveChanges = () => {
   if (currentField === 'orderID') {
     const orderIDs = tempInputValues.orderID.split(',').map((id) => id.trim());
     setInputValues((prevValues) => ({
       ...prevValues,
       orderID: [...prevValues.orderID, ...orderIDs],
     }));
   } else if (currentField === 'product') {
     setInputValues((prevValues) => ({
       ...prevValues,
       product: [...prevValues.product, ...tempInputValues.product],
     }));
   } else if (currentField === 'color') {
     setInputValues((prevValues) => ({
       ...prevValues,
       color: [...prevValues.color, ...tempInputValues.color],
     }));
   } else if (currentField === 'orderDate') {
     setInputValues((prevValues) => ({
       ...prevValues,
       fromOrderDate: tempInputValues.fromOrderDate,
       toOrderDate: tempInputValues.toOrderDate,
     }));
   } else if (currentField === 'deliveryDate') {
     setInputValues((prevValues) => ({
       ...prevValues,
       fromDeliveryDate: tempInputValues.fromDeliveryDate,
       toDeliveryDate: tempInputValues.toDeliveryDate,
     }));
   } else {
     setInputValues((prevValues) => ({
       ...prevValues,
       [currentField]: tempInputValues[currentField],
     }));
   }
   setShowModal(false);
   setTempInputValues({
     orderID: '',
     product: [],
     color: [],
     fromOrderDate: '',
     toOrderDate: '',
     fromDeliveryDate: '',
     toDeliveryDate: '',
     deliveryType: '',
   });
 };

 const getorderData = () => {
  // Create an object for the parameters
  const params = {
    orderIds: inputValues.orderID,
    fromOrderDate: inputValues.fromOrderDate,
    toOrderDate: inputValues.toOrderDate,
    fromDeliveryDate: inputValues.fromDeliveryDate,
    toDeliveryDate: inputValues.toDeliveryDate,
    deliveryTypes: inputValues.deliveryType,
    productNames: inputValues.product,
    productColors: inputValues.color
  };

  // Filter out fields that are null, undefined, or empty
  const filteredParams = Object.keys(params)
    .filter((key) => params[key] != null && params[key] !== '') // Exclude null, undefined, and empty strings
    .map((key) => `${key}=${encodeURIComponent(params[key])}`) // Encode each key-value pair
    .join('&');

  // If there are any valid parameters, make the API call
  const url = filteredParams ? `order/filter?${filteredParams}` : '';

  if (url) {
    // Make the API call if the URL is not empty
    ResponseFromServer('orders/order-process', url)
      .then((res) => {
        if (res.data && res.data.content) {
          setOrderData(res.data.content);
          props.setOrderFilter(res.data.content)
        } else {
          console.error('No content found in the response');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  } else {
    console.error('No valid parameters to send to the server');
  }
};


   useEffect(getorderData,[])

 const clearSingleOrderID = (index) => {
   const updatedOrderIDs = inputValues.orderID.filter((_, i) => i !== index);
   setInputValues((prev) => ({ ...prev, orderID: updatedOrderIDs }));
 };

 const clearSingleProduct = (index) => {
   const updatedProducts = inputValues.product.filter((_, i) => i !== index);
   setInputValues((prev) => ({ ...prev, product: updatedProducts }));
 };

 const clearSingleColor = (index) => {
   const updatedColors = inputValues.color.filter((_, i) => i !== index);
   setInputValues((prev) => ({ ...prev, color: updatedColors }));
 };
 const clearOrderDates = () => setInputValues((prev) => ({ ...prev, fromOrderDate: '', toOrderDate: '' }));
 const clearDeliveryDates = () => setInputValues((prev) => ({ ...prev, fromDeliveryDate: '', toDeliveryDate: '' }));
 const clearDeliveryType = () => setInputValues((prev) => ({ ...prev, deliveryType: '' }));


 const renderInputField = () => {
   switch (currentField) {
     case 'orderID':
       return (
         <Form.Group controlId="orderID">
           <Form.Label>Order ID</Form.Label>
           <Form.Control
             size="sm"
             type="text"
             name="orderID"
             placeholder="Enter Order ID"
             value={tempInputValues.orderID}
             onChange={handleInputChange}
           />
         </Form.Group>
       );
     case 'product':
       return (
         <Form.Group controlId="product">
           <Form.Label>Product</Form.Label>
           <Row>
         {products.map((product, index) => (
          <Col md={4} key={index}>
          <Form.Check
            key={index}
            type="checkbox"
            label={product}
            value={product}
            checked={tempInputValues.product.includes(product)}
            onChange={handleCheckboxChange}
          />
          </Col>
        ))}
      </Row>
         </Form.Group>
       );
     case 'color':
       return (
         <Form.Group controlId="color">
           <Form.Label>Color</Form.Label>
           <Row>
        {colors.map((color, index) => (
          <Col md={3} key={index}>
            <Form.Check
              type="checkbox"
              label={color}
              value={color}
              checked={tempInputValues.color.includes(color)}
              onChange={handleColorCheckboxChange}
            />
          </Col>
        ))}
      </Row>
         </Form.Group>
       );
     case 'orderDate':
       return (
         <Row>
           <Col>
             <Form.Group controlId="fromOrderDate">
               <Form.Label>From Order Date</Form.Label>
               <Form.Control
                 size="sm"
                 type="date"
                 name="fromOrderDate"
                 value={tempInputValues.fromOrderDate}
                 onChange={handleInputChange}
               />
             </Form.Group>
           </Col>
           <Col>
             <Form.Group controlId="toOrderDate">
               <Form.Label>To Order Date</Form.Label>
               <Form.Control
                 size="sm"
                 type="date"
                 name="toOrderDate"
                 value={tempInputValues.toOrderDate}
                 onChange={handleInputChange}
               />
             </Form.Group>
           </Col>
         </Row>
       );
     case 'deliveryDate':
       return (
         <Row>
           <Col>
             <Form.Group controlId="fromDeliveryDate">
               <Form.Label>From Delivery Date</Form.Label>
               <Form.Control
                 size="sm"
                 type="date"
                 name="fromDeliveryDate"
                 value={tempInputValues.fromDeliveryDate}
                 onChange={handleInputChange}
               />
             </Form.Group>
           </Col>
           <Col>
             <Form.Group controlId="toDeliveryDate">
               <Form.Label>To Delivery Date</Form.Label>
               <Form.Control
                 size="sm"
                 type="date"
                 name="toDeliveryDate"
                 value={tempInputValues.toDeliveryDate}
                 onChange={handleInputChange}
               />
             </Form.Group>
           </Col>
         </Row>
       );
      case 'deliveryType':
        return (
          <Row>
            <Col>
              <Form.Group controlId="deliveryType">
                <Row>
                  <Col md={6} className="mb-3 mb-md-0">
                    <Form.Check
                      type="radio"
                      className="mb-0 form-check radio-select"
                      label="STANDARD"
                      name="deliveryType"
                      value="STANDARD"
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md={6} className="mb-3 mb-md-0">
                    <Form.Check
                      type="radio"
                      className="mb-0 form-check radio-select"
                      label="EXPRESS"
                      name="deliveryType"
                      value="EXPRESS"
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Orders</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions">
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="id-badge"
                  transform="shrink-3"
                  onClick={() => handleShow('orderID')}
                >
                  <span className="d-none d-sm-inline-block ms-1">Order ID</span>
                </IconButton>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="box-open"
                  transform="shrink-3"
                  className="mx-2"
                  onClick={() => handleShow('product')}
                >
                  <span className="d-none d-sm-inline-block ms-1">Product</span>
                </IconButton>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="palette"
                  transform="shrink-3"
                  onClick={() => handleShow('color')}
                >
                  <span className="d-none d-sm-inline-block ms-1">Color</span>
                </IconButton>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="calendar-alt"
                  transform="shrink-3"
                  className="mx-2"
                  onClick={() => handleShow('orderDate')}
                >
                  <span className="d-none d-sm-inline-block ms-1">Order Date</span>
                </IconButton>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="calendar-day"
                  transform="shrink-3"
                  onClick={() => handleShow('deliveryDate')}
                >
                  <span className="d-none d-sm-inline-block ms-1">Delivery Date</span>
                </IconButton>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="truck"
                  transform="shrink-3"
                  className="mx-2"
                  onClick={() => handleShow('deliveryType')}
                >
                  <span className="d-none d-sm-inline-block ms-1">Delivery Type</span>
                </IconButton>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="search" // Search
                  transform="shrink-3"
                  className=" bg-success text-white "
                  onClick={() => getorderData()}
                >
                  <span className="d-none d-sm-inline-block ms-1">Search</span>
                </IconButton>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="fs--1">
        <Row className="mb-2">
                {/* Order ID Section */}
            <Col xs="auto mt-2 p-0">
            <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
              <div>
                {inputValues.orderID.length > 0 && (
                  <>
                    {inputValues.orderID.map((id, index) => (
                      <Badge key={index} bg="info" pill className="mx-1">
                        Order ID: <span className ="text-dark">{id}</span>
                        <Button variant="link" className="text-danger p-0 ms-2" size="sm" onClick={() => clearSingleOrderID(index)}>
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                      </Badge>

                    ))}
                  </>
                )}
              </div>
            </Flex>
          </Col>

                {/* Product Section */}
                <Col xs="auto mt-2 p-0">
             <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
               <div>
                 {inputValues.product.length > 0 && (
                   <>
                     {inputValues.product.map((prod, index) => (
                       <Badge key={index} bg="info" pill className="mx-1">
                         Product:<span className ="text-dark"> {prod}</span>
                         <Button variant="link" className="text-danger p-0 ms-2" size="sm"   onClick={() => clearSingleProduct(index)}>
                           <FontAwesomeIcon icon={faTimesCircle} />
                         </Button>
                       </Badge>
                     ))}
                   </>
                 )}
               </div>
             </Flex>
           </Col>

                {/* Color Section */}
                <Col xs="auto mt-2 p-0">
              <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
               <div>
                 {inputValues.color.length > 0 && (
                   <>
                     {inputValues.color.map((col, index) => (
                       <Badge key={index} bg="info" pill className="mx-1">
                         Color:<span className ="text-dark"> {col}</span>
                         <Button variant="link" className="text-danger p-0 ms-2" size="sm"   onClick={() => clearSingleColor(index)}>
                           <FontAwesomeIcon icon={faTimesCircle} />
                         </Button>
                       </Badge>
                     ))}
                   </>
                 )}
               </div>
             </Flex>
           </Col>
                <Col xs="auto mt-2 p-0">
                <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
                  <div>
                    {inputValues.fromOrderDate && inputValues.toOrderDate && (
                      <Badge bg="info" pill className="fs--2 mx-1">
                        Order Date: <span className ="text-dark">{inputValues.fromOrderDate}</span> to <span className ="text-dark">{inputValues.toOrderDate}</span>
                        <Button variant="link" className="text-danger p-0 ms-2" size="sm" onClick={clearOrderDates}>
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                      </Badge>
                    )}
                  </div>
                </Flex>
              </Col>
              {/* Delivery Date Section */}
              <Col xs="auto mt-2 p-0">
                <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
                  <div>
                    {inputValues.fromDeliveryDate && inputValues.toDeliveryDate && (
                      <Badge bg="info" pill className="fs--2 mx-1">
                        Delivery Date:<span className ="text-dark"> {inputValues.fromDeliveryDate}</span> to <span className ="text-dark">{inputValues.toDeliveryDate}</span>
                        <Button variant="link" className="text-danger p-0 ms-2" size="sm" onClick={clearDeliveryDates}>
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                      </Badge>
                    )}
                  </div>
                </Flex>
              </Col>
              {/* Delivery Type Section */}
              <Col xs="auto mt-2 p-0">
                <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
                  <div>
                    {inputValues.deliveryType && (
                      <Badge bg="info" pill className="fs--2 mx-1">
                        Delivery Type: <span className ="text-dark">{inputValues.deliveryType}</span>
                        <Button variant="link" className="text-danger p-0 ms-2" size="sm" onClick={clearDeliveryType}>
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                      </Badge>
                    )}
                  </div>
                </Flex>
              </Col>

         </Row>

        </Card.Body>
      </Card>

      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit {currentField}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderInputField()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderHeader;
