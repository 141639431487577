import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState,useEffect } from "react";
import Container from 'react-bootstrap/Container';
import {Modal,Form,Row,Col,Button} from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { sendData } from 'helpers/utils';
import { PutToServer } from 'helpers/utils';
import {useNavigate} from 'react-router-dom';

const Editlead =(props)=> {
  const initialData = props.data || {
   name: '',
   email: '',
   phone: '',
   id:''
 };

 const  navigate = useNavigate()
  const [inputValue, setInputValue] = useState(initialData);
  useEffect(() => {
   if (props.data) {
     setInputValue({
       name: props.data.name || '',
       email: props.data.email || '',
       phone: props.data.phoneNumber || '',
      id: props.data.id || ''
     });
   }
 }, [props.data]);
   function handleInputChange(event) {
   const { name, value } = event.target;
   setInputValue({
     ...inputValue,
     [name]: value
   });
 }


 const handleSubmit = (event) => {
 event.preventDefault();
 let data = {
   name: inputValue.name,
   email: inputValue.email,
   phoneNumber: inputValue.phone,
 }
PutToServer('/leadinfo/leaddetails', `/lead/${props.data.id}`, data).then((res)=>{
   props.setModalShow(false);
})
}
  return (
    <>
    {props.data === null ?(null):(
      <Modal
        show={props.modalShow}
        onHide={() => props.setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form >
          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Name" name='name' value={inputValue.name} onChange={handleInputChange} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Email" name='email' value={inputValue.email} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Phone</Form.Label>
              <Form.Control type="phone" placeholder="Phone" name='phone' value={inputValue.phone} onChange={handleInputChange}  />
            </Form.Group>

          </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button  type="submit" onClick={handleSubmit} >Save</Button>
        </Modal.Footer>
      </Modal>
    )}


    </>
  );
}
export default Editlead;
