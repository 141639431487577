import React,{useEffect,useState} from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import ImageModal from './OrderImageModal'
import ProductList from './productList';
import { ResponseFromServer } from 'helpers/utils';



const Orders = () => {

  const [orderList,setOrderList] = useState([]);
  const [show,setShow] = useState(false);
  const [images,setImages] = useState([])
  const getUserList =()=>{
    ResponseFromServer('orders/order-list','order').then((res)=>setOrderList(res.data))
  }

  const imagesModalPopUp =(image)=>{
    setShow(!show)
    setImages(image)

  }


  useEffect(() => {
      getUserList();
      return () => {
        setOrderList([]);
      };
  }, []);

  useEffect(getUserList,[])

  return (
    <>
    <ImageModal show={show} handleClose={setShow} images={images} />

      <Card className="mb-3">
        <Card.Header>
          <OrdersTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <ProductList />
        </Card.Body>

      </Card>

   </>
  );
};

export default Orders;
