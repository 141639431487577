export const initialState = {
  user: null,
  token: null,
  loading: false,
  errorMessage: null,
  expiryTime: null,
  profileIcon: null,
  profileMenu: false,
  profileMenuMobile: false
};

export const AuthReducer = (initialState, action) => {
    switch (action.type){
       case "SET_USER_LOGIN":
        return {
          ...initialState,
          user: action.payload.fnm,
          token: action.payload.accessToken,
          loading:false,
          expiryTime:action.payload.exp,
          errorMessage: null,
          profileIcon:action.payload.profile_img

        }
        break;
       case "TOKEN_REFRESH":
        return {
          ...initialState,
          user: action.payload.fnm,
          token: action.payload.accessToken,
          loading:false,
          expiryTime:action.payload.exp,
          errorMessage: null,
          profileIcon:action.payload.profile_img

        }
        break;
       case "LOGIN_SUCCESS":
        return {
          ...initialState,
          user: action.payload.fnm,
          token: action.payload.accessToken,
          expiryTime:action.payload.exp,
          loading: false,
          errorMessage: null,
          profileIcon:action.payload.profile_img
        };


        break;
        case "LOGIN_ERROR":
        return {
          ...initialState,
          loading: false,
          errorMessage: action.payload
        };

         break;
      case "LOGOUT_SUCCESS":
      return {
          ...initialState,
          user:null,
          token: null,
          loading: true,
          errorMessage: null,
          profileIcon:null
        };
        break;
      default:
        throw new Error(`Unhandled action type: ${action.type}`);

    }

}
