import PageHeader from 'components/common/PageHeader';
import LeadHistory from './leadhistory';
import LeadHeader from './leadheader';

const LeadDetails = () => {
  return (
    <>

<LeadHeader />
      <LeadHistory />

    </>
  );
};

export default LeadDetails;
