import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';

const CustomersTableHeader = ({ selectedRowIds }) => {
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString('default', { month: 'long' });
  const year = today.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Orders</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">

          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="id-badge" // Order ID
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Order ID</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="box-open" // Product
              transform="shrink-3"
              className="mx-2"
            >
              <span className="d-none d-sm-inline-block ms-1">Product</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="palette" // Color
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Color</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="mobile-alt" // Mobile Number
              transform="shrink-3"
              className="mx-2"
            >
              <span className="d-none d-sm-inline-block ms-1">Mobile Number</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="user" // Name
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Name</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="calendar" // Date
              transform="shrink-3"
              className="mx-2"
            >
              <span className="d-none d-sm-inline-block ms-1">{formattedDate}</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="info-circle" // Status
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Status</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="map-marker-alt" // State
              transform="shrink-3"
              className="mx-2"
            >
              <span className="d-none d-sm-inline-block ms-1">State</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="truck" // Delivery
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Delivery</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="search" // Search
              transform="shrink-3"
              className="mx-2 bg-success text-white "
            >
              <span className="d-none d-sm-inline-block ms-1">Search</span>
            </IconButton>
          </div>
      </Col>
    </Row>
  );
};

CustomersTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default CustomersTableHeader;
