import React, { useState } from "react";
import {Form} from 'react-bootstrap';


const DateFilter = ({ setData,date }) => {



  const handleDateChange = (e) => {


}

  return (
    <Form>
    <Form.Control
    type="date"
    value={date}
    onChange={setData}
    />
    </Form>
  );
};
export default DateFilter;
