import React, { useContext } from 'react';
import { Col, Modal, Row, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';

const ImageModal = (props) => {

  const {
    config: { isDark }
  } = useContext(AppContext);


  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : null}
        className="border-200"
      >
        <Modal.Title as="h5">
          <Flex alignItems="center">
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <Row className="g-0 align-items-center"  >
          {props.images.map((item,idx)=>(

            <Col key={idx}>
                <Image
                  src={item.imageDesktop}
                  rounded
                  className="me-3 d-none d-md-block"
                  width="200"
                  alt="product image"
                />
            </Col>

          ))}
         </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
