import {React,useEffect,useState} from "react";
import {fabric} from 'fabric';
import FontFaceObserver from 'fontfaceobserver';

const CalculateImageDimension =({imageUrl,canx,nos,node})=>{
  const [screenShot,setScreenShot] = useState(null)
  const makeCanvas = async()=>{
      var containerWidth = node.current.clientWidth;
      let containerHeight = 0;
      var computedStyle = window.getComputedStyle(node.current);
      var padding = parseInt(computedStyle.paddingLeft) + parseInt(computedStyle.paddingRight);
      var img = new Image();
      img.src = imageUrl;
      img.onload = async function () {
        let width = this.width;
        let height = this.height;
        var ratio = width / height;
        containerWidth = containerWidth - padding;
        containerHeight = containerWidth / ratio;
        let can = await convertImageData(canx);

        const canvas = new fabric.Canvas();

        await canvas.loadFromJSON(can, (obj) => {
            var scaleFactorX = containerWidth / can.currentWidth;
            var scaleFactorY = containerWidth / can.currentWidth;
            canvas.setDimensions({ width: containerWidth, height: containerHeight });
            canvas.getObjects().forEach((object) => {
                object.scaleX *= scaleFactorX;
                object.scaleY *= scaleFactorY;
                object.left *= scaleFactorX;
                object.top *= scaleFactorY;
                object.setCoords();
                if (object.clipPath) {
                    var scaleX = scaleFactorX;
                    var scaleY = scaleFactorY;
                    object.clipPath.scaleX *= scaleX;
                    object.clipPath.scaleY *= scaleY;
                    object.clipPath.left *= scaleX;
                    object.clipPath.top *= scaleY;
                    object.clipPath.setCoords();
                }
            });
            canvas.renderAll();
            let imageDataUrl = canvas.toDataURL({ format: 'png', quality: 1 });
            setScreenShot(imageDataUrl)
        })



      }


  }

  async function convertImageData(singleCanvas) {
const uniqueFontFamilies = new Set();

if (singleCanvas.objects.length > 0) {
  // Create an array to store promises for each conversion
  const conversionPromises = [];

  for (let i = 0; i < singleCanvas.objects.length; i++) {
    let tempImage = singleCanvas.objects[i];

    if (tempImage.type === 'i-text' || tempImage.type === 'text') {
      if (!tempImage.fontFamily.includes('Times New Roman')) {
        let combo = `${tempImage.fontFamily}-${tempImage.fontWeight || 'normal'}-${tempImage.fontStyle || 'normal'}`;
        uniqueFontFamilies.add(combo);
      }
    }
  }

  // If there are unique font families, load them
  if (uniqueFontFamilies.size !== 0) {
    const fontPromises = Array.from(uniqueFontFamilies).map((combo) => {
      const [fontFamily, fontWeight, fontStyle] = combo.split('-');
      const fontObserver = new FontFaceObserver(fontFamily, { weight: fontWeight, style: fontStyle });
      return fontObserver.load(null, 3000);
    });

    // Await all font promises
    await Promise.all(fontPromises);
  }

  for (let i = 0; i < singleCanvas.objects.length; i++) {
    let tempImage = singleCanvas.objects[i];

    if (tempImage.type === 'image') {
      // Create a promise for each conversion and store it in the array
      const conversionPromise = convertBase64(tempImage.src)
        .then((resolvedUrl) => {
          tempImage.myCustomData = tempImage.src;
          tempImage.src = resolvedUrl;
        })
        .catch((error) => {
          singleCanvas.objects = singleCanvas.objects.filter((item) => item.src !== error);
          console.error("An error occurred during conversion:", error);
        });

      conversionPromises.push(conversionPromise);
    }
  }

  // Wait for all conversion promises to resolve
  await Promise.all(conversionPromises);

  return singleCanvas;
}

return singleCanvas;
}



  const convertBase64 = async (source) => {
    return new Promise((resolve, reject) => {
        const newImage = new Image();
        let modifiedDataURL = ``;
        newImage.crossOrigin = "anonymous";
        newImage.src = source;
        newImage.onload = async function () {

            if(newImage.width * newImage.height <= 2073600 ){
              const imageCanvas = document.createElement("canvas");
              imageCanvas.width = newImage.width;
              imageCanvas.height = newImage.height;
              const ctx = imageCanvas.getContext("2d");
              ctx.drawImage(newImage, 0, 0);
              const imageData = ctx.getImageData(0, 0, imageCanvas.width, imageCanvas.height);
              const modifiedImage = imageData;
              const newContext = imageCanvas.getContext('2d');
              newContext.putImageData(modifiedImage, 0, 0);
              modifiedDataURL = imageCanvas.toDataURL('image/png');
              resolve(modifiedDataURL);

            }
            else{
              toDataURL(newImage.src).then(dataUrl => {
                resolve(dataUrl)
              })

            }

        };

        newImage.onerror = function () {
            reject(newImage.src);
            resolve(newImage.src);
        };
    });
};



const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))



  useEffect(makeCanvas,[])

  return(<img src={screenShot}   className="position-absolute top-0 start-0" />)





}

export default CalculateImageDimension;
