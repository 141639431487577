import { React, useReducer, useContext } from 'react';
import { initialState, AuthReducer } from './reducer';
import { AuthStateContext, AuthDispatchContext } from '../Context';
import { parseJwt } from 'helpers/utils';

export async function loginSuccess(dispatch, accessToken,refreshToken) {
    if(accessToken && refreshToken){

        let data = parseJwt(accessToken)
        data = {...data,accessToken}
        dispatch({ type: 'LOGIN_SUCCESS', payload: data });
        localStorage.setItem('access',accessToken)
        localStorage.setItem('refresh',refreshToken)

    }
}

export function loginError(dispatch,message){
 dispatch({ type: 'LOGIN_ERROR', payload:message });

}

export function setUserLogin(dispatch, accessToken,refreshToken){
      if(accessToken && refreshToken){
        let data = parseJwt(accessToken)
        data = {...data,accessToken}
        dispatch({ type: 'SET_USER_LOGIN', payload: data });
      }

}

export function setAccessToken(dispatch,accessToken){
    if(accessToken){
        localStorage.setItem('access',accessToken)
    }
}


export function logoutSuccess(dispatch, accessToken,refreshToken){
  localStorage.removeItem('access')
  localStorage.removeItem('refresh')
  dispatch({ type: 'LOGOUT_SUCCESS' });
}


//defining the Context

export function useAuthState(){
  const context = useContext(AuthStateContext)
  if(context === undefined){
    throw new Error("useAuthState Must Be Used within AuthProvider")


  }

  return context;

}

export function useAuthDispatch(){
  const context = useContext(AuthDispatchContext)
  if(context === undefined){
    throw new Error("useAuthDispatch Must Be Used within AuthProvider")
  }
  return context;

}


export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
