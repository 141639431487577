import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState,useEffect } from "react";
import Container from 'react-bootstrap/Container';
import {Modal,Form,Row,Col,Button} from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { sendData } from 'helpers/utils';
import { useDropzone } from 'react-dropzone';
import Images from 'components/common/Images';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import { getSize } from 'helpers/utils';
import { Dropdown } from 'react-bootstrap';


const Createlead =(props)=> {
const [files, setFiles] = React.useState([]);
  const [lead, setLead] = useState({
      name: '',
      email: '',
      phone: '',
       customerType:'',
      address: '',
      companyName:'',
      gender:'',
      society: '',
      pinCode:'',
      state: '',
      city: '',
      country: '',
      categoryType: '',
     neckType:'',
     fittingType: '',
     fabric:'',
     vendor: '',
     printingType: '',
     placements: '',
     quantity: '',
     rate: '',
     placements:'',
     quantity: '',
     rate: '',
     totalAmount:'',
     followUpDate:''

    });


    const handleChange = (event) => {
       const { name, value } = event.target;
       setLead({
         ...lead,
         [name]: value,
       });
       if (name === 'quantity' || name === 'rate') {
    calculateTotalAmount();
  }
     };


     const handleSubmit = (event) => {
        event.preventDefault()
       let data = {
         name: lead.name,
         email: lead.email,
         phoneNumber: lead.phone,
        customerType: lead.customerType,
         address: lead.address,
         companyName:lead.companyName,
         gender:lead.gender,
         society: lead.society,
         pinCode:lead.pinCode,
         state: lead.state,
         city: lead.city,
         country: lead.country,
         categoryType: lead.categoryType,
        neckType:lead.neckType,
        fittingType: lead.fittingType,
        fabric:lead.fabric,
        vendor: lead.vendor,
        printingType: lead.printingType,
        placements: lead.placements,
        quantity: lead.quantity,
        rate: lead.rate,
        placements:lead.placements,
        quantity: lead.quantity,
        rate: lead.rate,
        totalAmount:lead.totalAmount,
        followUpDate:lead.followUpDate
       }
       sendData('leadinfo/leaddetails','lead',data).then((res) => console.log(res));
props.setSmShow(false)
      }
      useEffect(() => {

  if (!props.smShow) {
    setLead({
      name: '',
      email: '',
      phone: '',
       customerType:'',
      address: '',
      companyName:'',
      gender:'',
      society: '',
      pinCode:'',
      state: '',
      city: '',
      country: '',
      categoryType: '',
     neckType:'',
     fittingType: '',
     fabric:'',
     vendor: '',
     printingType: '',
     placements: '',
     quantity: '',
     rate: '',
     placements:'',
     quantity: '',
     rate: '',
     totalAmount:'',
     followUpDate:''
    });
  }
}, [props.smShow]);


    const { getRootProps, getInputProps } = useDropzone({
      accept: 'image/*',
      onDrop: acceptedFiles => {
        setFiles(
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        );
      }
    });

    const handleRemove = path => {
      setFiles(files.filter(file => file.path !== path));
    };
    const calculateTotalAmount = () => {
  const quantity = parseFloat(lead.quantity) || 0;
  const rate = parseFloat(lead.rate) || 0;
  const totalAmount = quantity * rate;
  setLead((prevLead) => ({ ...prevLead, totalAmount: totalAmount.toFixed(2) }));
};



    return (
      <>
      <Modal
        size="xl"
        aria-labelledby="example-modal-sizes-title-sm"
        show={props.smShow} onRequestClose={() => props.setSmShow(false)}

      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            Create Lead
          </Modal.Title>
          <FalconCloseButton size='sm' onClick={() => props.setSmShow(false)}/>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Name" name='name' value={lead.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Phone</Form.Label>
              <Form.Control type="text" placeholder="Phone" name='phone' value={lead.phone} onChange={handleChange}  />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Email" name='email' value={lead.email} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Customer Type</Form.Label>
              <Form.Control type="text" placeholder="Customer Type" name='customerType' value={lead.customerType} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Company/College</Form.Label>
              <Form.Control type="text" placeholder="Company/College"  name='companyName' value={lead.companyName} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Society</Form.Label>
              <Form.Control type="text" placeholder="Society" name='society' value={lead.society} onChange={handleChange} />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
          <Form.Group as={Col} sm={4} controlId="formGridAddress1">
            <Form.Label>Address</Form.Label>
            <Form.Control  placeholder="1234 Main St" name='address' value={lead.address} onChange={handleChange} />
          </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Pin Code</Form.Label>
              <Form.Control type="number" placeholder="Pin Code" name='pinCode' value={lead.pinCode} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>State</Form.Label>
              <Form.Control type="text" placeholder="State" name='state' value={lead.state} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" placeholder="City" name='city' value={lead.city} onChange={handleChange}/>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" placeholder="Country" name='country' value={lead.country} onChange={handleChange}/>
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Label>Products</Form.Label>
              </Row>
            <Row className="mb-3 g-3 border border-200 rounded">

            <Form.Group as={Col} controlId="formGridPassword" >
              <Form.Label>categoryType</Form.Label>
              <Form.Select className="mb-2" name='categoryType' value={lead.categoryType} onChange={handleChange}>
        <option value=''>Category Type</option>
        <option value='TSHIRT'>TSHIRT </option>
        <option value='HOODIE'>HOODIE</option>
        <option value='JACKET'> JACKET</option>
        <option value='SWEATSHIRT'>SWEATSHIRT</option>
        <option value='BAG'>BAG</option>
        <option value='MASK'>MASK</option>
        <option value='VEST'> VEST</option>
        <option value='TANK_TOP'>TANK_TOP</option>
      </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword" >
              <Form.Label>Neck Type</Form.Label>
              <Form.Select className="mb-2" name='neckType' value={lead.neckType} onChange={handleChange}>
        <option value=''>Neck Type</option>
        <option value='ROUND_NECK'>ROUND_NECK</option>
        <option value=' V_NECK'> V_NECK</option>
        <option value='DEEP_NECK'> DEEP_NECK</option>
      </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword" >
              <Form.Label>Gender</Form.Label>
              <Form.Select className="mb-2" name='gender' value={lead.gender} onChange={handleChange}>
              <option value=''>Gender</option>
              <option value='MALE'>MALE</option>
              <option value='FEMALE'>FEMALE</option>
              <option value='UNISEX'>UNISEX</option>
      </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword" >
              <Form.Label>Fabric</Form.Label>
              <Form.Select className="mb-2" name='fabric'  value={lead.fabric} onChange={handleChange}>
              <option value=''>Fabric</option>
              <option value=' PURE_COTTON'> PURE_COTTON</option>
              <option value='POLYESTER'> POLYESTER</option>
              <option value='POLY_COTTON'> POLY_COTTON</option>
      </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword" >
              <Form.Label>Vendor</Form.Label>
                <Form.Control type="text" placeholder="Vendor" name='vendor' value={lead.vendor} onChange={handleChange}/>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword" >
              <Form.Label>Fitting Type</Form.Label>
              <Form.Select className="mb-2" name='fittingType' value={lead.fittingType} onChange={handleChange}>
              <option value=''>Fitting Type</option>
              <option value='SLIM'>SLIM</option>
              <option value='REGULAR'>REGULAR</option>
              <option value='LOOSE'> LOOSE</option>
              <option value='OVER_SIZE'>OVER_SIZE</option>
              <option value='FREE_SIZE'>FREE_SIZE</option>
              <option value='DROP_SHOULDER'>DROP_SHOULDER</option>
              <option value='STANDARD'> STANDARD</option>
      </Form.Select>
            </Form.Group>
            <Row className="g-2" style={{height:'90px'}}>
            <Form.Group as={Col}sm={2} controlId="formGridPassword" >
              <Form.Label>Printing Type</Form.Label>
                <Form.Control type="text" placeholder="Printing Type" name='printingType' value={lead.printingType} onChange={handleChange}/>
            </Form.Group>
            <Form.Group as={Col} sm ={2} controlId="formGridPassword" >
              <Form.Label>Placement</Form.Label>
              <Form.Select className="mb-2" name='placements' value={lead.placements} onChange={handleChange}>
              <option value=''>Placements</option>
              <option value=' FRONT'>FRONT</option>
              <option value=' BACK'> BACK</option>
              <option value='LEFT'>LEFT</option>
              <option value='RIGHT'>RIGHT</option>
      </Form.Select>
            </Form.Group>
            <Form.Group as={Col}sm={2} controlId="formGridPassword">
              <Form.Label>Date</Form.Label>
              <Form.Control type="date" placeholder="followUpDate" name='followUpDate' value={lead.followUpDate} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col}sm={1} controlId="formGridPassword">
              <Form.Label>Quantity</Form.Label>
              <Form.Control type="text" placeholder="quantity" name='quantity' value={lead.quantity} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col}sm={1} controlId="formGridPassword">
              <Form.Label>Rate</Form.Label>
              <Form.Control type="text" placeholder="Rate" name='rate' value={lead.rate} onChange={handleChange}/>
            </Form.Group>
            <Form.Group as={Col}sm={2} controlId="formGridPassword">
              <Form.Label>Total Amount</Form.Label>
              <Form.Control type="text" placeholder="Total Amount" name='totalAmount' value={lead.totalAmount}  onChange={handleChange}/>
            </Form.Group>
            </Row>
            <Row>
            <Form.Group as={Col} sm={1} controlId="formGridPassword" className="ms-n1" >
              <Form.Label>XS</Form.Label>
              <Form.Control  type="number" placeholder="XS" onChange={handleChange}  />
            </Form.Group>
            <Form.Group as={Col} sm={1} controlId="formGridPassword" className="ms-n3">
              <Form.Label>S</Form.Label>
              <Form.Control style={{ paddingRight: '5px' }} type="number" placeholder="S" onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={1} controlId="formGridPassword" className="ms-n3">
              <Form.Label>M</Form.Label>
              <Form.Control style={{ paddingRight: '5px' }} type="number" placeholder="M" onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={1} controlId="formGridPassword" className="ms-n3">
              <Form.Label>L</Form.Label>
              <Form.Control style={{ paddingRight: '5px' }} type="number" placeholder="L" onChange={handleChange}/>
            </Form.Group>
            <Form.Group as={Col} sm={1} controlId="formGridPassword" className="ms-n3">
              <Form.Label>XL</Form.Label>
              <Form.Control style={{ paddingRight: '5px' }} type="number" placeholder="XL" onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={1} controlId="formGridPassword" className="ms-n3">
              <Form.Label>2XL</Form.Label>
              <Form.Control style={{ paddingRight: '5px' }} type="number" placeholder="2XL" onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={1} controlId="formGridPassword" className="ms-n3">
              <Form.Label>3XL</Form.Label>
              <Form.Control style={{ paddingRight: '5px' }} type="number" placeholder="3XL" onChange={handleChange}/>
            </Form.Group>
            <Form.Group as={Col} sm={1} controlId="formGridPassword" className="ms-n3" >
              <Form.Label>4XL</Form.Label>
              <Form.Control style={{ paddingRight: '5px' }} type="number" placeholder="4XL" onChange={handleChange}/>
            </Form.Group>
            <Form.Group as={Col}sm={1} controlId="formGridPassword"  className="ms-n3">
              <Form.Label>5XL</Form.Label>
              <Form.Control style={{ paddingRight: '5px' }} type="number" placeholder="5XL" onChange={handleChange}/>
            </Form.Group>
            </Row>


</Row>




          <Button variant="success" type="submit">
            Create Lead
          </Button>

        </Form>
        </Modal.Body>
      </Modal>


    </>
  );
}
export default Createlead;
